import t from 'react-translate';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';

enum TabName {
  SUPERVISOR_COURSES = 'supervisor_courses',
  PENDING_SUBMISSIONS = 'pending_submissions',
}

const renderIcon = (
  IconComponent: React.ComponentType<React.SVGProps<SVGElement>>,
) => (
  <div>
    <IconComponent width={doubleSpacing} height={doubleSpacing} />
  </div>
);

const DropdownItem = ({
  title,
  counter,
  onClick,
  selected,
  ...rest
}: {
  title: string;
  counter: number;
  selected: boolean;
  onClick: () => void;
}) => (
  <div className='dropdown-option' onClick={onClick} {...rest}>
    <div>
      {title} {!!counter && <span className='highlight'>({counter} {t.SUPERVISOR_ADMIN_DASHBOARD.DROPDOWN_SUFFIX()})</span>}
    </div>
    <div>
      {selected && (
        <div className='icon icon-check completed-icon status text-warning rounded-circle' />
      )}
    </div>
  </div>
);

export { TabName, DropdownItem, renderIcon };
